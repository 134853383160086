import React, { useState } from "react";
import {
  AppBar,
  Button,
  MenuList,
  MenuListItem,
  Separator,
  TextInput,
  Toolbar,
  Window,
  WindowContent,
  WindowHeader,
} from "react95";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import logoIMG from "./assets/images/logo1.png";
import aboutMeIMG from "./assets/images/aboutme-95.png";
import projectIMG from "./assets/images/closed-folder.png";
import homeIMG from "./assets/images/home-95.png";
import trashIMG from "./assets/images/trash.png";
import original from "react95/dist/themes/original";
import { styleReset } from "react95";
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";
import { ScrollView } from "react95";
import miffyIMG from "./assets/images/miffy.png";
import india1 from "./assets/images/india/india1.jpeg";
import india2 from "./assets/images/india/india2.jpeg";
import india3 from "./assets/images/india/india3.jpeg";
import india4 from "./assets/images/india/india4.jpg";
import india5 from "./assets/images/india/india5.jpg";
import india6 from "./assets/images/india/india6.jpg";

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body, input, select, textarea {
    font-family: 'ms_sans_serif';
  }

  /* Hide scrollbar but allow scrolling */
  body::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  body {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    overflow-y: scroll; 
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: teal; 
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: teal;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px 20px 20px 20px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 50px;
  margin-top: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 50px; /* Space between the two columns */
`;

const IconItem = styled.div`
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  align-items: center;
  margin-bottom: 40px;
  color: white;
  font-size: 14px;
`;

const WindowWrapper = styled.div`
  position: relative;
  margin-right: 500px;

  .window-main {
    width: 445px; /* Adjust the width of the main window */
    min-height: 40px;
    margin-top: 90px;
    position: relative;
    z-index: 1; /* Ensure the main window is behind the smaller one */
  }

  .window-small {
    width: 300px; /* Smaller width for the second window */
    min-height: 150px;
    position: absolute;
    bottom: -120px; /* Adjust the overlap position to the bottom */
    right: -200px; /* Adjust the overlap position to the right */
    z-index: 2; /* Ensure the smaller window is on top */
  }

  .window-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0.5rem;
  }

  .close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    transform: rotateZ(45deg);
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      background: ${({ theme }) => theme.materialText};
    }
    &:before {
      height: 100%;
      width: 3px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      height: 3px;
      width: 100%;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .footer {
    display: block;
    margin: 0.25rem;
    height: 31px;
    line-height: 31px;
    padding-left: 0.25rem;
  }
`;

const Photos = () => {
  const [open, setOpen] = useState(false);
  const [isMainWindowOpen, setMainWindowOpen] = useState(true);
  const [isSmallWindowOpen, setSmallWindowOpen] = useState(true);
  const [isMiffyWindowOpen, setMiffyWindowOpen] = useState(true);

  const [isIndiaGalleryOpen, setIndiaGalleryOpen] = useState(false); // State for India gallery window
  const [currentIndiaImageIndex, setCurrentIndiaImageIndex] = useState(0); // Track current image index in the gallery

  const indiaImages = [
    india1,
    india2,
    india3,
    india4,
    india5,
    india6,
    // Add more URLs here
  ];

  console.log(currentIndiaImageIndex);
  console.log(indiaImages);
  return (
    <ThemeProvider theme={original}>
      <GlobalStyles />
      <AppBar>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <Button
              primary
              onClick={() => setOpen(!open)}
              active={open}
              style={{ fontWeight: "bold" }}
            >
              <img
                src={logoIMG}
                alt="react95 logo"
                style={{ height: "30px", marginRight: 7 }}
              />
              Start
            </Button>
            {open && (
              <MenuList
                style={{
                  position: "absolute",
                  left: "0",
                  top: "100%",
                }}
                onClick={() => setOpen(false)}
              >
                <MenuListItem>
                  <a
                    href="/about-me"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={aboutMeIMG}
                      alt="About Me"
                      style={{ height: "20px", marginRight: "8px" }}
                    />
                    About Me
                  </a>
                </MenuListItem>
                <MenuListItem>
                  <a
                    href="/my-projects"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={projectIMG}
                      alt="Projects"
                      style={{ height: "20px", marginRight: "8px" }}
                    />
                    Projects
                  </a>
                </MenuListItem>
                <Separator />
                <MenuListItem>
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={homeIMG}
                      alt="Home"
                      style={{ height: "20px", marginRight: "4px" }}
                    />
                    Home
                  </a>
                </MenuListItem>
              </MenuList>
            )}
          </div>

          <TextInput placeholder="Search..." width={150} />
        </Toolbar>
      </AppBar>

      <Wrapper>
        <IconContainer>
          <Column>
            <IconItem onClick={() => setIndiaGalleryOpen(true)}>
              <img
                src={projectIMG}
                alt="Icon 1"
                style={{
                  height: "40px",
                  marginBottom: "5px",
                  marginTop: "30px",
                }}
              />
              <span>india</span>
            </IconItem>
            <IconItem>
              <img
                src={projectIMG}
                alt="Icon 1"
                style={{ height: "40px", marginBottom: "5px" }}
              />
              <span>portugal</span>
            </IconItem>
            <IconItem>
              <img
                src={projectIMG}
                alt="Icon 1"
                style={{ height: "40px", marginBottom: "5px" }}
              />
              <span>spain</span>
            </IconItem>
            <IconItem>
              <img
                src={trashIMG}
                alt="Trash"
                style={{ height: "40px", marginBottom: "5px" }}
              />
              <span>Trash</span>
            </IconItem>
          </Column>
          <Column>
            <IconItem>
              <img
                src={projectIMG}
                alt="Icon 2"
                style={{
                  height: "40px",
                  marginBottom: "5px",
                  marginTop: "30px",
                }}
              />
              <span>france</span>
            </IconItem>
            <IconItem>
              <img
                src={projectIMG}
                alt="Icon 3"
                style={{ height: "40px", marginBottom: "5px" }}
              />
              <span>baking</span>
            </IconItem>
            <IconItem>
              <img
                src={projectIMG}
                alt="Icon 4"
                style={{ height: "40px", marginBottom: "5px" }}
              />
              <span>kitty!</span>
            </IconItem>
          </Column>
        </IconContainer>

        <WindowWrapper>
          {isMainWindowOpen && (
            <Window resizable className="window-main">
              <WindowHeader className="window-title">
                <span>about my photography.exe</span>
                <Button onClick={() => setMainWindowOpen(false)}>
                  <span className="close-icon" />
                </Button>
              </WindowHeader>
              <Toolbar>
                <Button variant="menu" size="sm">
                  File
                </Button>
                <Button variant="menu" size="sm">
                  Edit
                </Button>
                <Button variant="menu" size="sm" disabled>
                  Save
                </Button>
              </Toolbar>
              <WindowContent>
                <ScrollView style={{ width: "400px", height: "240px" }}>
                  <p>
                    hi! thank you for visiting my page and my photography, i'm
                    so happy to have you here :-D while my photography has never
                    been professional, i have always loved doing it since i was
                    a kid. i wanted to put this page together as a way to
                    reflect on the thousands of pictures i've taken and to mark
                    some amazing countries i had the privilege of visiting. i
                    want to look back on here and scrapbook some of my most
                    cherished moments, i hope you enjoy them just as much as i
                    do &lt; 3
                  </p>
                </ScrollView>
              </WindowContent>
            </Window>
          )}

          {isSmallWindowOpen && (
            <Window className="window-small">
              <WindowHeader className="window-title">
                <span>:-0.exe</span>
                <Button onClick={() => setSmallWindowOpen(false)}>
                  <span className="close-icon" />
                </Button>
              </WindowHeader>
              <WindowContent>
                <p>
                  i also really enjoy cooking and baking (as well as cats!)
                  please enjoy the many pictures i've taken of them :-D
                </p>
              </WindowContent>
            </Window>
          )}
          {isMiffyWindowOpen && (
            <Window
              className="miffy-window"
              style={{
                position: "absolute",
                bottom: "100px",
                left: "550px",
                width: "270px",
                zIndex: 3,
              }}
            >
              <WindowHeader className="window-title">
                <span>miffy.exe</span>
                <Button onClick={() => setMiffyWindowOpen(false)}>
                  <span className="close-icon" />
                </Button>
              </WindowHeader>
              <WindowContent>
                <img
                  src={miffyIMG}
                  alt="Miffy"
                  style={{ width: "100%", height: "auto" }}
                />
              </WindowContent>
            </Window>
          )}

          {isIndiaGalleryOpen && (
            <Window
              className="india-gallery-window"
              style={{
                position: "absolute",
                top: "100px",
                right: "20px",
                width: "500px", // Adjust the width of the window
                height: "535px", // Adjust the height of the window
                zIndex: 4,
              }}
            >
              <WindowHeader className="window-title">
                <span>india-photos.exe</span>
                <Button onClick={() => setIndiaGalleryOpen(false)}>
                  <span className="close-icon" />
                </Button>
              </WindowHeader>
              <WindowContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center", // Center vertically
                    justifyContent: "center", // Center horizontally
                    height: "100%", // Ensure the container takes full height of WindowContent
                  }}
                >
                  <div
                    style={{
                      width: "400px",
                      height: "400px",
                      display: "flex",
                      alignItems: "center", // Center the image vertically inside this container
                      justifyContent: "center", // Center the image horizontally inside this container
                    }}
                  >
                    <img
                      src={indiaImages[currentIndiaImageIndex]}
                      alt="India"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain", // Ensure the image scales properly inside the container
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={() =>
                      setCurrentIndiaImageIndex((prev) =>
                        prev > 0 ? prev - 1 : indiaImages.length - 1
                      )
                    }
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() =>
                      setCurrentIndiaImageIndex((prev) =>
                        prev < indiaImages.length - 1 ? prev + 1 : 0
                      )
                    }
                  >
                    Next
                  </Button>
                </div>
              </WindowContent>
            </Window>
          )}
        </WindowWrapper>
      </Wrapper>
    </ThemeProvider>
  );
};

export default Photos;
