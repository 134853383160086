import React, { useEffect, useState } from 'react';
import { Application } from '@splinetool/runtime';
import miffygif from './assets/images/rosy-cheeks-flush.gif'; // Import the gif
import SoraFont from './assets/fonts/sora/Sora-Regular.ttf';

const SplineCanvas = () => {
  const [isLoading, setIsLoading] = useState(true); // State to manage loading

  useEffect(() => {
    const canvas = document.getElementById('canvas3d');
    const app = new Application(canvas);

    // Load the Spline scene
    app.load('https://prod.spline.design/rRJxyTYdu8DbIkBS/scene.splinecode')
      .then(() => {
        // Add a 2-3 second delay after loading
        setTimeout(() => {
          setIsLoading(false);
        }, 280);
      })
      .catch((error) => {
        console.error('Error loading Spline scene:', error);
        setIsLoading(false); // Hide loading screen on error
      });
  }, []);

  // Handle the GIF loading and stop the loading screen after it plays once
  const handleGifLoad = () => {
    const gifElement = document.getElementById('gif-loading');
    
    // Wait until the gif is fully loaded and then hide the loading screen after it finishes
    gifElement.onload = () => {
      // Add a timeout to stop the loading screen after the gif has played
      setTimeout(() => {
        setIsLoading(false); // Stop the loading screen after the gif plays once
      }, 4000);  // Duration of your gif animation (adjust if necessary)
    };
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
      {/* Loading Screen */}
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffb59b',
            zIndex: 1,
            textAlign: 'center',
          }}
        >
          {/* Embedded GIF */}
          <img
            id="gif-loading"
            src={miffygif}
            alt="Rosy Cheeks Flush Sticker"
            onLoad={handleGifLoad} // Call the function when the GIF loads
            style={{
              width: '250px',
              height: '250px',
              marginBottom: '1rem',
            }}
          />
          {/* Text */}
          <h1
            style={{
              fontSize: '2rem',
              color: '#ffff',
              marginBottom: '0.5rem',
              fontFamily: SoraFont,  
            }}
          >
            welcome to riya's website!
          </h1>
        </div>
      )}

      {/* Spline Canvas */}
      <canvas
        id="canvas3d"
        style={{
          width: '100%',
          height: '100%',
          visibility: isLoading ? 'hidden' : 'visible',
        }}
      ></canvas>
    </div>
  );
};

export default SplineCanvas;
