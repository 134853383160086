import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import SoraFont from "./assets/fonts/sora/Sora-Regular.ttf";
import bottomImage from "./assets/images/footer.png";
import topImage from "./assets/images/top.png";
import riya from "./assets/images/riya.png";
import resumeButtonImage from "./assets/images/resume-button.png"; // Import the resume button image

// GlobalStyles to apply Sora font
const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Sora';
    src: url(${SoraFont}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body, input, select, textarea {
    font-family: 'Sora', sans-serif;
    margin: 0;
    padding: 0;
    overflow-y: auto; 
    height: 100%; 
  }

  #root, html, body {
    height: 100%; 
  }
`;

const Container = styled.div`
  z-index: 1;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 150px;
  box-sizing: border-box;
  background: radial-gradient(circle at 30% 30%, #ffd6e3, #fbcdbf, #d6ebff);

  @media (max-width: 768px) {
    padding-bottom: 100px;
  }
`;

const Content = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 0.8rem;
  max-width: 800px;
  transform: translateX(30px);
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 0.7rem;
    transform: translateX(0);
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
  margin-top: -15px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const AboutText = styled.p`
  font-size: 1.5rem;
  color: #555;
  line-height: 1.6;
  margin-top: 0px;
  margin-bottom: 0px;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ResumeButton = styled.a`
  display: inline-block;
  margin-top: 20px;
  z-index: 1000;
`;

const ResumeImage = styled.img`
  width: 300px;
  height: auto;
  cursor: pointer;
  margin-left: 15px;
  z-index: 1000;

  @media (max-width: 768px) {
    width: 200px;
    margin-left: 0;
  }
`;

const FooterImage = styled.img`
  width: 60%;
  height: 650px;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;

const TopImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 750px;
  height: auto;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const Sidebar = styled.div`
  position: absolute;
  top: 210px;
  left: 0;
  width: 200px;
  height: calc(100% - 300px);
  background-color: rgba(0, 0, 0, 0);
  padding: 25px 0 25px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 10px;
    top: auto;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  padding-left: 20px;
  border-left: 3px solid #333;
  margin-top: 10px;

  @media (max-width: 768px) {
    padding-left: 0;
    border-left: none;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
`;

const NavItem = styled.a`
  color: #333;
  text-decoration: none;
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
  width: 200%;

  &:hover {
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const RiyaImage = styled.img`
  margin-top: 20px;
  width: 200px;
  height: auto;
  margin-left: 0px;
  margin-bottom: -10px;

  @media (max-width: 768px) {
    width: 150px;
    margin-top: 10px;
  }
`;

const Projects = () => {
  return (
    <>
      <GlobalStyles />
      <Container>
      <TopImage src={topImage} alt="Top Left" />
      <Sidebar>
        <RiyaImage src={riya} alt="riya icon" />
        <NavItemsContainer>
          <NavItem href="/">home</NavItem>
          <NavItem href="/photos">my photos</NavItem>
          <NavItem href="/my-projects">my projects</NavItem>
        </NavItemsContainer>
      </Sidebar>
      <Content>
      <Title>hi, i'm riya!</Title>
      <AboutText>
      welcome to my page! <br></br> i love designing, programming, and creating{" "}
      <br></br> something new. i am currently a computer science <br></br>{" "}
      student at the university of waterloo, and <br></br> love traveling +
      baking + doodling.
      <br />
      <ResumeButton>
        <a href="/resume.pdf" target="_blank" rel="noopener noreferrer">
          <ResumeImage src={resumeButtonImage} alt="Resume Button" />
        </a>
      </ResumeButton>
      <br />i made this space to share a glimpse <br></br> of my experiences,
      projects, and interests!
      </AboutText>
      </Content>
      <FooterImage src={bottomImage} alt="Footer" />
      </Container>
    </>
  );
};

export default Projects;
