import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import SoraFont from "./assets/fonts/sora/Sora-Regular.ttf";
import projectImage from "./assets/images/aboutme-95.png"; // Replace with your image paths
import bannerImage from "./assets/images/miffy-banner.jpg"; // Add your banner image here
import vspace from "./assets/images/vspace.jpeg";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Sora';
    src: url(${SoraFont}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body, input, select, textarea {
    font-family: 'Sora', sans-serif;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    height: 100%; 
    background-color: rgb(230, 225, 215); 
  }

  #root, html, body {
    height: 100%; 
  }
`;

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: rgb(
    228,
    220,
    212
  ); /* Ensure entire container has background */
`;

const BannerImage = styled.img`
  width: 40%;
  height: auto;
  margin-bottom: 50px;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: rgba(2, 85, 153, 255); /* Set title color */
  margin-bottom: 50px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
  height: 300px;
  box-sizing: border-box;
`;

const Image = styled.img`
  width: 45%;
  height: 100%;
  object-fit: cover;
`;

const TextContainer = styled.div`
  width: 50%;
  padding: 20px;
  font-size: 1.2rem;
  color: #333;
  text-align: left;
`;

const ProjectTitle = styled.h2`
  color: rgba(2, 85, 153, 255); /* Set project title color */
`;

const Projects = () => {
  return (
    <>
      <GlobalStyles />
      <PageContainer>
        <Title>My Projects</Title>
        <BannerImage src={bannerImage} alt="Banner" />

        <Container style={{ flexDirection: "row-reverse" }}>
          <Image
            src={vspace}
            alt="Designed a Mural for a Toronto Co-Working Space"
          />
          <TextContainer>
            <ProjectTitle>
              <a
                href="https://x.com/VSpaceTO"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "rgba(2,85,153,255)" }}
              >
                Designed a Mural for a Premier Toronto Co-Working Space
              </a>
            </ProjectTitle>
            <p>
              Helped with the design of VSpace which is a co-working space in downtown Toronto.
            </p>
          </TextContainer>
        </Container>
        {/* Project 1 */}
        <Container>
          <Image
            src="https://miro.medium.com/v2/resize:fit:1400/format:webp/0*B3FeDFxXaVk8kGof.jpg"
            alt="Building a Number Detecting Neural Network"
          />
          <TextContainer>
            <ProjectTitle>
              <a
                href="https://rriyakumar.medium.com/building-a-number-detecting-neural-network-d69c813d59a5"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "rgba(2,85,153,255)" }}
              >
                Building a Number Detecting Neural Network
              </a>
            </ProjectTitle>
            <p>
              Learn how to create a simple neural network to classify
              handwritten digits using the MNIST dataset. This project covers
              data preprocessing, model architecture, training, and evaluation.
            </p>
          </TextContainer>
        </Container>

        {/* Project 2 */}
        <Container style={{ flexDirection: "row-reverse" }}>
          <Image
            src="https://miro.medium.com/v2/resize:fit:1400/format:webp/0*LRf3Tn1EJ6wRkyi1.png"
            alt="How to build a basic Neural Network"
          />
          <TextContainer>
            <ProjectTitle>
              <a
                href="https://rriyakumar.medium.com/how-to-build-a-basic-neural-network-d02a0c1ff252"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "rgba(2,85,153,255)" }}
              >
                How to Build a Basic Neural Network
              </a>
            </ProjectTitle>
            <p>
              An introduction to neural networks, this project demonstrates how
              to build and train a basic neural network from scratch, providing
              a solid foundation for beginners in AI and machine learning.
            </p>
          </TextContainer>
        </Container>

        {/* Project 3 */}
        <Container>
          <Image
            src="https://miro.medium.com/v2/resize:fit:1400/format:webp/0*GRIQS-n2JrY8g5kq.jpg"
            alt="Blockchain at the Doctor’s Office?"
          />
          <TextContainer>
            <ProjectTitle>
              <a
                href="https://rriyakumar.medium.com/blockchain-at-the-doctors-office-8aad2001c6cb"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "rgba(2,85,153,255)" }}
              >
                Blockchain at the Doctor’s Office?
              </a>
            </ProjectTitle>
            <p>
              This article explores the intersection of blockchain and
              healthcare, discussing how decentralized technology can improve
              data security, patient privacy, and streamline administrative
              processes in medical systems.
            </p>
          </TextContainer>
        </Container>
      </PageContainer>
    </>
  );
};

export default Projects;
